import React from "react";
import App, { AppProps } from "next/app";
import { MitraProvider } from "../core/context/MitraContext";
import { SaHiringProvider } from "../core/context/SAHiringContext";
import * as Sentry from "@sentry/browser";
import Variables from "../components/styles/variables";
import Fonts from "../components/styles/font";
import Global from "../components/styles/global";
import PosLocatorGlobalStyles from "src/components/styles/pos-locator-global-styles";

Sentry.init({
  dsn: "https://3b89c05ccb444d9bad6e3263d13c747d@o394302.ingest.sentry.io/5244236",
  environment: process.env.NODE_ENV,
});

// Define the state type
interface State {
  isStylesLoaded: boolean;
}

// Define the component props type, including the Next.js AppProps
interface MyAppProps extends AppProps {}

class _app extends App<MyAppProps, {}, State> {
  constructor(props: MyAppProps) {
    super(props);
    this.state = {
      isStylesLoaded: false,
    };
  }

  componentDidMount() {
    // Use a timeout to simulate waiting for styles to load
    setTimeout(() => {
      this.setState({ isStylesLoaded: true });
    }, 0); // You can adjust the timeout if necessary
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps, router } = this.props;
    const pathname = router.pathname;
    const { isStylesLoaded } = this.state;

    // Render a loading state while styles are being applied
    if (!isStylesLoaded) {
      return null;
    }

    return (
      <React.Fragment>
        <Variables />
        <Fonts />
        {pathname === "/temukan-lokasi-kami" ? (
          <PosLocatorGlobalStyles />
        ) : (
          <Global />
        )}
        <SaHiringProvider>
          <MitraProvider>
            <Component {...pageProps} />{" "}
          </MitraProvider>
        </SaHiringProvider>
      </React.Fragment>
    );
  }
}

export default _app;
